<template>
  <AppNavbar />
  <main class="container-fluid px-2">
      <section class="hero-section position-relative overflow-hidden d-flex align-items-center">
      <div class="container">
        <div class="row align-items-center py-5 mt-5">
          <div class="col-lg-6 text-center text-lg-start">
            <h1 class="display-4 fw-bold text-primary mb-4 lh-sm text-gradient-primary">
              Gestioná tu negocio de forma eficiente y obtené más ventas
            </h1>
            <p class="lead mb-5 col-lg-10">
              Te ayudamos a optimizar tus procesos y a aumentar tus ventas con las últimas tecnologías.
          </p>
            <div class="d-flex gap-3 justify-content-center justify-content-lg-start">
              <a href="mailto:soporte@propmind.com.ar" class="btn btn-primary px-3 py-3">
                <i class="fas fa-rocket me-2"></i>Comenzar ahora
              </a>
              <a href="/demo" class="btn btn-outline-primary px-3 py-3">
                <i class="fas fa-play me-2"></i>Ver demo
              </a>
            </div>
          </div>
          <div class="col-lg-6 d-none d-lg-block position-relative mt-5 mt-lg-0">
            <div class="hero-image-wrapper d-flex justify-content-center">
              <img src="@/assets/illustrations/hero.svg" alt="Dashboard Preview" class="img-fluid col-lg-9" />
            </div>
            <div class="floating-card bg-white p-3 rounded-3 shadow position-absolute">
              <div class="d-flex align-items-center">
                <i class="fas fa-chart-line text-primary fa-2x me-3"></i>
                <div>
                  <h5 class="mb-0 fs-6">Aumentá tus ventas</h5>
                  <p class="text-muted mb-0 fs-7">Convierte más leads en clientes</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Decorative elements -->
      <div class="decoration-circle decoration-circle-1"></div>
      <div class="decoration-circle decoration-circle-2"></div>
    </section>

    <!-- Integrations Section -->
    <div class="container-fluid bg-light py-5" id="integrations">
      <div class="row g-4 d-flex justify-content-center">
        <div class="col-6 col-md-4 col-lg-2">
          <div class="stat-card p-4 rounded-4 d-flex align-items-center justify-content-center">
            <img src="@/assets/logos/zonaprop.png" alt="zonaprop logo" class="brand-logo-img">
          </div>
        </div>
        <div class="col-6 col-md-4 col-lg-2">
          <div class="stat-card p-4 rounded-4 d-flex align-items-center justify-content-center">
            <img src="@/assets/logos/mercadolibre.png" alt="mercadolibre logo" class="brand-logo-img">
          </div>
        </div>
        <div class="col-6 col-md-4 col-lg-2">
          <div class="stat-card p-4 rounded-4 d-flex align-items-center justify-content-center">
            <img src="@/assets/logos/properati.png" alt="properati logo" class="brand-logo-img">
          </div>
        </div>
        <div class="col-6 col-md-4 col-lg-2">
          <div class="stat-card p-4 rounded-4 d-flex align-items-center justify-content-center">
            <img src="@/assets/logos/inmoclick.png" alt="inmoclick logo" class="brand-logo-img">
          </div>
        </div>
        <div class="col-6 col-md-4 col-lg-2">
          <div class="stat-card p-4 rounded-4 d-flex align-items-center justify-content-center">
            <img src="@/assets/logos/olx.png" alt="olx logo" class="brand-logo-img">
          </div>
        </div>
        <div class="col-6 col-md-4 col-lg-2">
          <div class="stat-card p-4 rounded-4 d-flex align-items-center justify-content-center">
            <img src="@/assets/logos/argenprop.png" alt="argenprop logo" class="brand-logo-img">
          </div>
        </div>
      </div>
    </div>

    <section class="container py-5">
      <div class="text-center mb-5 py-5">
        <span
            class="badge rounded bg-primary-subtle text-primary px-3 py-2 mb-3 fs-5"
          >
            ¿Por qué elegirnos?
          </span>
        <h2 class="display-5 mb-4">La solución completa para tu inmobiliaria</h2>
        <p class="lead text-muted">
          Diseñado específicamente para hacer crecer tu negocio inmobiliario
        </p>
      </div>
  
      <div class="row g-4">
        <!-- Experiencia -->
        <div class="col-md-6 col-lg-3">
          <div class="card h-100 border-0 shadow-sm bg-light-hover">
            <div class="card-body text-center">
              <div class="mb-4">
                <i class="fas fa-chart-line text-primary fa-3x"></i>
              </div>
              <h5 class="card-title mb-3">Impulsa tu crecimiento</h5>
              <p class="card-text text-muted">
                Optimiza tus procesos y aumenta tus ventas con herramientas diseñadas para el éxito
              </p>
            </div>
          </div>
        </div>
  
        <!-- Soporte -->
        <div class="col-md-6 col-lg-3">
          <div class="card h-100 border-0 shadow-sm bg-light-hover">
            <div class="card-body text-center">
              <div class="mb-4">
                <i class="fas fa-headset text-primary fa-3x"></i>
              </div>
              <h5 class="card-title mb-3">Soporte dedicado</h5>
              <p class="card-text text-muted">
                Equipo de soporte especializado disponible cuando lo necesites
              </p>
            </div>
          </div>
        </div>
  
        <!-- Tecnología -->
        <div class="col-md-6 col-lg-3">
          <div class="card h-100 border-0 shadow-sm bg-light-hover">
            <div class="card-body text-center">
              <div class="mb-4">
                <i class="fas fa-laptop-code text-primary fa-3x"></i>
              </div>
              <h5 class="card-title mb-3">Tecnología moderna</h5>
              <p class="card-text text-muted">
                Plataforma actualizada constantemente con las últimas tecnologías
              </p>
            </div>
          </div>
        </div>
  
        <!-- Seguridad -->
        <div class="col-md-6 col-lg-3">
          <div class="card h-100 border-0 shadow-sm bg-light-hover">
            <div class="card-body text-center">
              <div class="mb-4">
                <i class="fas fa-shield-alt text-primary fa-3x"></i>
              </div>
              <h5 class="card-title mb-3">Máxima seguridad</h5>
              <p class="card-text text-muted">
                Tus datos siempre protegidos con los más altos estándares de seguridad
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="website-showcase container bg-white my-5 py-5 px-2">
      <div class="row align-items-end bg-white">
        <div class="col-lg-5 order-2 order-lg-1">
          <span class="badge rounded bg-primary-subtle text-primary px-3 py-2 mb-3 fs-5">
            <i class="fa-solid fa-globe me-2"></i>Sitio Web Profesional
          </span>
          <h2 class="display-5 mb-4">Tu inmobiliaria con presencia online</h2>
          <p class="lead text-muted mb-4 col-lg-10">
            Construímos tu sitio web inmobiliario con las últimas tecnologías y un diseño personalizado para tu marca
          </p>
          <div class="mb-4">
            <ul class="list-unstyled">
              <li class="mb-3">
                <i class="fa-solid fa-check text-primary me-3"></i>
                Diseño personalizado 100% adaptado a tu marca
              </li>
              <li class="mb-3">
                <i class="fa-solid fa-check text-primary me-3"></i>
                Catálogo de propiedades con filtros inteligentes
              </li>
              <li class="mb-3">
                <i class="fa-solid fa-check text-primary me-3"></i>
                Optimizado para SEO y dispositivos móviles
              </li>
            </ul>
          </div>
          <div class="d-flex gap-3">
            <a href="/demo" class="btn btn-outline-primary px-4 py-3 mt-2">
              <i class="fa-solid fa-eye me-2"></i>Ver demo
            </a>
            <a href="mailto:soporte@propmind.com.ar" class="btn btn-primary px-4 py-3 mt-2">
              <i class="fa-solid fa-rocket me-2"></i>Contratar ahora
            </a>
          </div>
        </div>
        <div class="col-lg-7 d-flex align-items-end order-1 order-lg-2 mb-5 mb-lg-0">
          <div class="position-relative">
            <img 
              src="@/assets/images/sample-web-home.png" 
              alt="Sitio Web Inmobiliaria" 
              class="img-fluid rounded-2"
            /> 
          </div>
        </div>
      </div>
    </section>



    <!-- Pricing Section -->
    <section class="pricing-section py-5">
      <div class="container">
        <!-- Header -->
        <div class="text-center mb-5">
          <span class="badge rounded bg-primary-subtle text-primary px-3 py-2 mb-3 fs-5">
            <i class="fa-solid fa-tag me-2"></i>Precios
          </span>
          <h2 class="display-4 fw-bold mb-3">
            Elige el plan perfecto para tu negocio
          </h2>
          <p class="lead text-muted col-lg-8 mx-auto">
            Digitaliza y potencia tu negocio inmobiliario con nuestras soluciones
          </p>
        </div>
  
        <!-- Pricing Cards -->
        <div class="row g-4 justify-content-center mb-5">
          <!-- Starter Plan -->
          <div class="col-lg-4">
            <div class="pricing-card card h-100 border rounded-4 shadow-sm hover-lift">
              <div class="card-body p-4">
                <div class="text-center mb-4">
                  <div class="icon-wrapper mb-3">
                    <i class="fa-solid fa-user-tie fs-2 text-primary"></i>
                  </div>
                  <h3 class="mb-3">Individual</h3>
                  <div class="pricing-value">
                    <span class="display-4 fw-bold">AR$25.000</span>
                    <span class="text-muted">/mes</span>
                  </div>
                </div>
                <ul class="feature-list">
                  <li>
                    <i class="fa-solid fa-check text-primary me-2"></i>1 Usuario
                  </li>
                  <li>
                    <i class="fa-solid fa-check text-primary me-2"></i>15 Propiedades
                  </li>
                  <li>
                    <i class="fa-solid fa-check text-primary me-2"></i>5 Emprendimientos
                  </li>
                  <li>
                    <i class="fa-solid fa-check text-primary me-2"></i>Sitio web básico
                  </li>
                  <li>
                    <i class="fa-solid fa-check text-primary me-2"></i>Certificado SSL incluido
                  </li>
                  <li>
                    <i class="fa-solid fa-check text-primary me-2"></i>Publicación en portales gratuitos
                  </li>
                  <li class="text-muted">
                    <i class="fa-solid fa-times text-primary me-2"></i>Correos electronicos personalizados
                  </li>
                  <li class="text-muted">
                    <i class="fa-solid fa-times me-2"></i>Soporte prioritario
                  </li>
                  <li class="text-muted">
                    <i class="fa-solid fa-times me-2"></i>Páginas web por propiedad
                  </li>
                </ul>
                <div class="text-center mt-4">
                  <a href="mailto:soporte@propmind.com.ar" class="btn btn-outline-primary px-4 py-2 w-100">
                    Comenzar ahora
                  </a>
                </div>
              </div>
            </div>
          </div>
  
          <!-- Professional Plan -->
          <div class="col-lg-4">
            <div class="pricing-card card h-100 border-0 rounded-4 bg-primary text-white shadow hover-lift-primary">
              <div class="card-body p-4">
                <div class="text-center mb-4">
                  <span class="badge bg-white text-primary px-3 py-2 mb-3">Más popular</span>
                  <div class="icon-wrapper mb-3">
                    <i class="fa-solid fa-users fs-2 text-white"></i>
                  </div>
                  <h3 class="mb-3 text-white">Equipo</h3>
                  <div class="pricing-value">
                    <span class="display-4 fw-bold">AR$40.000</span>
                    <span class="text-light">/mes</span>
                  </div>
                </div>
                <ul class="feature-list text-white">
                  <li>
                    <i class="fa-solid fa-check me-2"></i>5 Usuarios
                  </li>
                  <li>
                    <i class="fa-solid fa-check me-2"></i>50 Propiedades
                  </li>
                  <li>
                    <i class="fa-solid fa-check me-2"></i>15 Emprendimientos
                  </li>
                  <li>
                    <i class="fa-solid fa-check me-2"></i>Sitio web personalizado
                  </li>
                  <li>
                    <i class="fa-solid fa-check me-2"></i>Certificado SSL incluido
                  </li>
                  <li>
                    <i class="fa-solid fa-check me-2"></i>Publicación en portales gratuitos
                  </li>
                  <li>
                    <i class="fa-solid fa-check me-2"></i>1 Correo electronico personalizado
                  </li>
                  <li>
                    <i class="fa-solid fa-check me-2"></i>Soporte prioritario
                  </li>
                  <li class="text-muted">
                    <i class="fa-solid fa-times me-2"></i>Páginas web por propiedad
                  </li>
                </ul>
                <div class="text-center mt-4">
                  <a href="mailto:soporte@propmind.com.ar" class="btn btn-light px-4 py-2 w-100">
                    Comenzar ahora
                  </a>
                </div>
              </div>
            </div>
          </div>
  
          <!-- Corporate Plan -->
          <div class="col-lg-4">
            <div class="pricing-card card h-100 border rounded-4 shadow-sm hover-lift">
              <div class="card-body p-4">
                <div class="text-center mb-4">
                  <div class="icon-wrapper mb-3">
                    <i class="fa-solid fa-building fs-2 text-primary"></i>
                  </div>
                  <h3 class="mb-3">Corporativo</h3>
                  <div class="pricing-value">
                    <span class="display-4 fw-bold">AR$70.000</span>
                    <span class="text-muted">/mes</span>
                  </div>
                </div>
                <ul class="feature-list">
                  <li>
                    <i class="fa-solid fa-check text-primary me-2"></i>Usuarios ilimitados
                  </li>
                  <li>
                    <i class="fa-solid fa-check text-primary me-2"></i>Propiedades ilimitadas
                  </li>
                  <li>
                    <i class="fa-solid fa-check text-primary me-2"></i>Emprendimientos ilimitados
                  </li>
                  <li>
                    <i class="fa-solid fa-check text-primary me-2"></i>Sitio web personalizado
                  </li>
                  <li>
                    <i class="fa-solid fa-check text-primary me-2"></i>Certificado SSL incluido
                  </li>
                  <li>
                    <i class="fa-solid fa-check text-primary me-2"></i>Publicación en portales gratuitos
                  </li>
                  <li>
                    <i class="fa-solid fa-check text-primary me-2"></i>5 Correos electronicos personalizados
                  </li>
                  <li>
                    <i class="fa-solid fa-check text-primary me-2"></i>Soporte prioritario
                  </li>
                  <li>
                    <i class="fa-solid fa-check text-primary me-2"></i>Páginas web por propiedad
                  </li>
                </ul>
                <div class="text-center mt-4">
                  <a href="mailto:soporte@propmind.com.ar" class="btn btn-outline-primary px-4 py-2 w-100">
                    Comenzar ahora
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="container-fluid d-flex justify-content-center align-items-center py-5 px-0" id="faq">
      <!-- FAQ Section -->
      <div class="container row mt-6 d-flex justify-content-center px-0">
        <div class="col-lg-5 d-none d-lg-flex align-items-center justify-content-center px-0">
          <img
            src="@/assets/illustrations/faq.svg"
            alt="FAQ"
            class="img-fluid rounded-4"
          />
        </div>
        <div class="col-lg-6 px-0">
          <div class=" mb-5">
            <h2 class="display-4 fw-bold mb-3">Preguntas frecuentes</h2>
            <p class="lead text-muted mx-auto">
              Todo lo que necesitas saber sobre nuestra plataforma
            </p>
          </div>
  
          <div class="row justify-content-center">
            <div class="col-lg-12">
              <div class="accordion" id="faqAccordion">
                <div class="accordion-item border-0 shadow-sm mb-3 rounded-3">
                  <h3 class="accordion-header">
                    <button
                      class="accordion-button rounded-3"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq1"
                    >
                      ¿Puedo cambiar de plan en cualquier momento?
                    </button>
                  </h3>
                  <div
                    id="faq1"
                    class="accordion-collapse collapse show"
                    data-bs-parent="#faqAccordion"
                  >
                    <div class="accordion-body">
                      Sí, puedes actualizar o cambiar tu plan en cualquier
                      momento. Los cambios se reflejarán en tu próxima
                      facturación.
                    </div>
                  </div>
                </div>
  
                <div class="accordion-item border-0 shadow-sm mb-3 rounded-3">
                  <h3 class="accordion-header">
                    <button
                      class="accordion-button collapsed rounded-3"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq2"
                    >
                      ¿Qué métodos de pago aceptan?
                    </button>
                  </h3>
                  <div
                    id="faq2"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqAccordion"
                  >
                    <div class="accordion-body">
                      Aceptamos todas las tarjetas de crédito principales,
                      transferencias bancarias y Mercado Pago.
                    </div>
                  </div>
                </div>
  
                <div class="accordion-item border-0 shadow-sm mb-3 rounded-3">
                  <h3 class="accordion-header">
                    <button
                      class="accordion-button collapsed rounded-3"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq3"
                    >
                      ¿Ofrecen soporte técnico?
                    </button>
                  </h3>
                  <div
                    id="faq3"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqAccordion"
                  >
                    <div class="accordion-body">
                      Todos los planes incluyen soporte por email. Los planes
                      Equipo y Corporativo incluyen soporte prioritario y
                      acceso a nuestro equipo técnico.
                    </div>
                  </div>
                </div>
  
                <div class="accordion-item border-0 shadow-sm rounded-3">
                  <h3 class="accordion-header">
                    <button
                      class="accordion-button collapsed rounded-3"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq4"
                    >
                      ¿Necesito instalar algo para usar la plataforma?
                    </button>
                  </h3>
                  <div
                    id="faq4"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqAccordion"
                  >
                    <div class="accordion-body">
                      No, nuestra plataforma es 100% basada en la nube. Solo
                      necesitas un navegador web moderno para acceder.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <CTABanner class="py-5"/>
    
  </main>
  <AppFooter />
</template>

<script>
  import CTABanner from "@/components/CTABanner.vue";
  import AppNavbar from "@/components/Navbar.vue";
  import AppFooter from "@/components/Footer.vue";
export default {
  name: "HomePage",
  components: {
    CTABanner,
    AppNavbar,
    AppFooter,
  },
};
</script>

<style scoped>
  .floating-card {
  bottom: -70px;
  right: 0;
  transform: translateY(-50%);
}
.hero-section {
  background: linear-gradient(to bottom, #ffffff, #f8f9fa);
  min-height: 80vh;
}
.brand-logo-img {
  max-height: 40px;
  margin: 0 auto;
  filter: grayscale(100%);
  opacity: 0.5;
}

.website-mockup {
  perspective: 1000px;
  transform-style: preserve-3d;
}

.website-devices {
  transform: translateZ(20px);
}

.mobile-device {
  position: absolute;
  bottom: -50px;
  right: -50px;
  width: 150px;
  opacity: 0.9;
  filter: drop-shadow(0 10px 20px rgba(0,0,0,0.1));
}
.features-section {
  background: linear-gradient(to bottom, #ffffff, #f8f9fa);
}

.feature-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.feature-icon-wrapper {
  height: 80px;
  display: flex;
  align-items: center;
}

.feature-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.feature-list li {
  padding: 0.5rem 0;
  font-weight: 500;
}

.bg-light-hover {
  background: rgba(248, 249, 250, 0.5);
}

/* pricin */
.pricing-section {
  background: linear-gradient(to bottom, #f8f9fa, #ffffff);
}

.feature-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.feature-list li {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

.hover-lift {
  transition: transform 0.2s ease;
}

.hover-lift:hover {
  transform: translateY(-5px);
}

.hover-lift-primary:hover {
  transform: translateY(-5px);
  box-shadow: 0 1rem 3rem rgba(var(--bs-primary-rgb), 0.175);
}

.icon-wrapper {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: rgba(var(--bs-primary-rgb), 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.pricing-card.bg-primary .icon-wrapper {
  background: rgba(255, 255, 255, 0.1);
}

@media (max-width: 991.98px) {
  .pricing-card {
    margin-bottom: 2rem;
  }
}

/* end pricing */
.accordion-button:not(.collapsed) {
  background-color: var(--primary-color);
  color: white;
}

.accordion-button:focus {
  box-shadow: none;
  border-color: var(--primary-color);
}

.mt-6 {
  margin-top: 4rem;
}

.py-6 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
</style>
