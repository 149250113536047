<template>
  <AppNavbar/>
  <div class="hero px-3">
    <div class="hero-content text-center d-flex align-items-center flex-column">
      <h1 class="display-2 fw-bold text-white col-lg-8 text-gradient-light">
        Oportunidades inmobiliarias en Argentina
      </h1>
      <p style="color: var(--white-color-2)" class="py-3">
        Navegá miles de propiedades y encontrá el lugar que te hará feliz
      </p>
      <div class="hero-search d-flex justify-content-center my-4 w-100">
        <div class="input-group">
          <!-- dropdown type -->
          <div class="input-group-text p-0">
            <select class="form-select" id="inputGroupSelect01">
              <option value="1">Casa</option>
              <option value="2">Departamento</option>
              <option value="3">Terreno</option>
              <option value="4">Local</option>
              <option value="5">Oficina</option>
            </select>
          </div>
          <input
            type="text"
            class="form-control"
            placeholder="Ingresá dirección, ciudad o zona"
            aria-label="Search"
            aria-describedby="button-addon2"
          />
          <a href="/demo/propiedades" class="btn btn-primary" type="button" id="button-addon2">
            <i class="fas fa-search"></i> Buscar
          </a>
        </div>
      </div>
    </div>
  </div>
  <!-- property list -->
  <div class="container my-5 bg-light px-3">
    <h2>Explorá propiedades en tu zona</h2>
    <!-- filter buttons -->
    <div class="d-flex my-4">
      <button
        class="btn bg-transparent me-2 active property-filter-button"
        id="filterNew"
        @click="filterProperties('new')"
      >
        Nuevas
      </button>
      <button
        class="btn bg-transparent me-2 property-filter-button"
        id="filterPopular"
        @click="filterProperties('popular')"
      >
        Más visitadas
      </button>
    </div>
    <div class="row">
      <div class="col-md-4" v-for="property in properties" :key="property.id">
        <PropertyCard :property="property" />
      </div>
      <div class="col-12 d-flex justify-content-center my-3">
        <a class="btn btn-outline-primary py-2 px-3 fs-5" href="/demo/propiedades">
          Ver todas
          <i class="fas fa-arrow-right ms-2"></i>
        </a>
      </div>
    </div>
  </div>
  <!-- tasaciones section -->
  <section class="container-fluid d-flex justify-content-center mt-5 py-5 px-3">
    <div class="container d-flex row py-5 mt-3">
      <div class="row">
        <div class="col-lg-6 order-2 order-md-1">
          <div class="">
            <div class="card-body">
              <h2 class="fw-bold fs-2">¿Querés vender tu propiedad?</h2>
              <p class="text-muted" style="font-size: 1.1rem">
                Conocé el valor de tu propiedad en el mercado actual y tomá la
                mejor decisión.
                Nosotros te ayudamos a tasar tu propiedad de forma rápida y
                segura.
              </p>
              <button class="btn btn-primary mt-3" @click="getStarted">
                <i class="fas fa-dollar-sign me-2"></i>
                Pedir tasación
              </button>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <img
            src="./static/illustrations/for-sale.svg"
            alt="home for sale illustration"
            class="img-fluid mb-md-0 mb-5 order-1 order-md-2"
          />
        </div>
      </div>
    </div>
  </section>
  <section class="container-fluid d-flex justify-content-center">
    <div class="container d-flex row pb-5 mt-3">
      <div class="row">
        <div class="col-lg-6 d-flex align-items-center order-md-1 order-2">
          <div class="">
            <div class="card-body">
              <h2 class="fw-bold fs-2">Emprendimientos</h2>
              <p class="text-muted" style="font-size: 1.1rem">
                Conocé los mejores emprendimientos en construcción y en pozo.
                El lugar ideal para invertir en tu futuro.
              </p>
              <button class="btn btn-primary mt-3" @click="getStarted">
                <i class="fas fa-building me-2"></i>
                Ver emprendimientos
              </button>
            </div>
          </div>
        </div>
        <div class="col-lg-6 mb-md-0 mb-5 order-md-2 order-1">
          <img
            src="./static/illustrations/under-construction.svg"
            alt="home for sale illustration"
            class="img-fluid"
          />
        </div>
      </div>
    </div>
  </section>
  <section class="container-fluid d-flex justify-content-center bg-light py-5">
    <div class="container d-flex row pb-5 mt-3">
      <div class="col-lg-5">
        <ContactForm />
      </div>
      <div class="col-lg-7">
        <div class="">
          <div class="card-body pt-lg-5 ps-lg-5 mt-lg-3">
            <h2 class="fw-bold fs-2">Ponete en contacto con nosotros</h2>
            <p class="text-muted" style="font-size: 1.1rem">
              ¿Necesitás ayuda o tenés alguna consulta? Contactanos y te
              responderemos a la brevedad.
            </p>
            <div class="contact-options d-flex my-5 gap-3 flex-column">
              <div class="d-flex align-items-center">
                <i class="fas fa-phone-alt fs-3 text-primary me-3"></i>
                <div>
                  <h5 class="fw-bold">Llamanos</h5>
                  <p class="text-muted">+54 9 11 1234 5678</p>
                </div>
              </div>
              <div class="d-flex align-items-center">
                <i class="fas fa-envelope fs-3 text-primary me-3"></i>
                <div>
                  <h5 class="fw-bold">Escribinos</h5>
                  <p class="text-muted">
                    <a href="mailto:soporte@propmind.com.ar" class="text-primary">soporte@propmind.com.ar </a>
                  </p>
                </div>
              </div>
              <div class="d-flex align-items-center">
                <i class="fas fa-map-marker-alt fs-3 text-primary me-3"></i>
                <div>
                  <h5 class="fw-bold">Visitanos</h5>
                  <p class="text-muted" style="max-width: 300px">
                    Av. Libertador 1500, Palermo, CABA
                  </p>
                </div>
              </div>
              <div class="d-flex align-items-center">
                <i class="fas fa-clock fs-3 text-primary me-3"></i>
                <div>
                  <h5 class="fw-bold">Horario de atención</h5>
                  <p class="text-muted">Lunes a Viernes de 9 a 18 hs</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
    <CTABanner class="mb-5"/>
  <AppFooter class="mt-5"/>
</template>

<script>
import AppNavbar from "./components/Navbar.vue";
import AppFooter from "./components/Footer.vue";
import CTABanner from "./components/CTABanner.vue";
import ContactForm from "./components/ContactForm.vue";
import PropertyCard from "./components/PropertyCard.vue";
export default {
  name: "DemoHome",
  components: {
    AppNavbar,
    PropertyCard,
    AppFooter,
    ContactForm,
    CTABanner,
  },
  data() {
    return {
      properties: [
        {
          id: 1,
          address: "Av. Libertador 1500, Palermo, CABA",
          description:
            "Espectacular departamento en el corazón de Palermo. Luminoso, con acabados de primera y vista panorámica a la ciudad.",
          price: 350000,
          type: "Departamento",
          status: "En Venta",
          bedrooms: 3,
          bathrooms: 2,
          area: 120,
          image: 'propiedad-1.webp'
        },
        {
          id: 2,
          address: "Barrio Cerrado Los Castores, Pilar, Buenos Aires",
          description:
            "Casa moderna en exclusivo barrio cerrado. Amplios espacios verdes, seguridad 24/7 y excelentes amenities para toda la familia.",
          price: 550000,
          type: "Casa",
          status: "Nuevo",
          bedrooms: 4,
          bathrooms: 3,
          area: 280,
          image: 'propiedad-2.webp'
        },
        {
          id: 3,
          address: "Belgrano 456, Mendoza Capital",
          description:
            "Propiedad histórica con encanto colonial, ubicada en el centro de Mendoza. Perfecta para inversión o vivienda familiar.",
          price: 280000,
          type: "Casa",
          status: "Oportunidad",
          bedrooms: 3,
          bathrooms: 2,
          area: 200,
          image: 'thumnail-1.jpg'
        },
        {
          id: 4,
          address: "Puerto Madero, CABA",
          description:
            "Loft de diseño con vista al río. Terminaciones de alta gama, amenities de primer nivel y ubicación incomparable.",
          price: 420000,
          type: "Loft",
          status: "En Venta",
          bedrooms: 2,
          bathrooms: 2,
          area: 90,
          image: 'propiedad-4.webp'
        },
        {
          id: 5,
          address: "Villa Carlos Paz, Córdoba",
          description:
            "Casa de fin de semana con vista al lago. Perfecta para descansar y disfrutar de la naturaleza. Ideal para inversión turística.",
          price: 250000,
          type: "Casa",
          status: "Rebajada",
          bedrooms: 3,
          bathrooms: 2,
          area: 160,
          image: 'propiedad-5.webp'
        },
        {
          id: 6,
          address: "San Telmo, CABA",
          description:
            "Departamento con el encanto típico del barrio porteño. Techos altos, detalles originales y ubicación histórica.",
          price: 210000,
          type: "Departamento",
          status: "Oportunidad",
          bedrooms: 2,
          bathrooms: 1,
          area: 85,
          image: 'propiedad-3.webp'
        },
      ],
    };
  },
  methods: {
    getStarted() {
      // Add your logic here
      console.log("Get Started clicked");
    },
    filterProperties(filter) {
      // Add your logic here
      console.log("Filter properties by", filter);
    },
  },
};
</script>

<style scoped>

.hero {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url("./static/images/hero.jpg");
  background-size: cover;
  background-position: center;
}
.text-gradient-light {
  background: linear-gradient(90deg, #ffffff 0%, #dbdbdb 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.hero-content {
  text-align: center;
  color: white;
}

.hero-search .input-group {
  max-width: 800px;
}

.hero-search .form-control {
  border-radius: 0.25rem 0 0 0.25rem;
  border-right: none;
}

.hero-search .btn {
  border-radius: 0 0.25rem 0.25rem 0;
}

.hero-search .input-group-text {
  border-radius: 0.25rem 0 0 0.25rem;
  max-width: 120px;
}

.hero-search .form-select {
  border-radius: 0.25rem 0 0 0.25rem;
  border-right: none;
  height: 100%;
}
.property-filter-button {
  color: var(--black-color-2);
  border: none;
  background: none;
  font-weight: 600;
  font-size: 1rem;
}

.property-filter-button.active {
  color: var(--primary-color);
  border-bottom: 2px solid var(--primary-color);
}

@media (max-width: 768px) {
  .hero {
    text-align: center;
    padding: 3rem 0;
  }
  /* select width */
    .input-group-text {
        max-width: 100px !important;
    }
}
</style>
