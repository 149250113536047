<template>
  <header class="fixed-top w-100">
    <!-- Desktop Navbar -->
    <nav class="navbar navbar-expand-lg px-3" :class="{ 'navbar-scrolled': scrolled }">
      <div class="container">
        <a href="/" class="navbar-brand py-2">
          <img src="@/assets/logo.svg" alt="Logo" class="logo" width="auto" height="60">
        </a>

        <!-- Desktop Menu -->
        <div class="d-none d-lg-flex align-items-center justify-content-between flex-grow-1">
          <ul class="navbar-nav mx-auto">
            <li class="nav-item">
              <a href="/" class="nav-link">Inicio</a>
            </li>
            <li class="nav-item">
              <a href="/precios" class="nav-link">Precios</a>
            </li>
            <li class="nav-item dropdown">
              <a 
                class="nav-link dropdown-toggle" 
                href="#" 
                @mouseenter="showIntegrationsMenu = true"
                @mouseleave="showIntegrationsMenu = false"
              >
                Integraciones
                <i class="fas fa-chevron-down ms-2"></i>
              </a>
              <div 
                class="dropdown-menu" 
                :class="{ 'show': showIntegrationsMenu }"
                @mouseenter="showIntegrationsMenu = true"
                @mouseleave="showIntegrationsMenu = false"
              >
                <a href="/integraciones/whatsapp" class="dropdown-item">Chats de Whatsapp</a>
                <a href="/integraciones/portales-inmobiliarios" class="dropdown-item">Portales Inmobiliarios</a>
                <a href="/integraciones/google-calendar" class="dropdown-item">Google Calendar</a>
              </div>
            </li>
            <li class="nav-item">
              <a href="/faq" class="nav-link">Blog</a>
            </li>
          </ul>
          <a class="btn btn-primary" href="mailto:soporte@propmind.com">Contáctanos</a>
        </div>

        <!-- Mobile Menu Button -->
        <button 
          class="navbar-toggler d-lg-none" 
          type="button" 
          @click="toggleMenu" 
          :aria-expanded="isMenuOpen"
          aria-label="Toggle navigation"
        >
          <span class="hamburger" :class="{ 'is-active': isMenuOpen }">
            <span class="hamburger-line"></span>
            <span class="hamburger-line"></span>
            <span class="hamburger-line"></span>
          </span>
        </button>
      </div>
    </nav>

    <!-- Mobile Menu -->
    <div 
      class="mobile-menu" 
      :class="{ 'is-open': isMenuOpen }"
      @click.self="closeMenu"
    >
      <div class="mobile-menu-container">
        <ul class="nav flex-column">
          <li class="nav-item">
            <a @click="closeMenu" href="/" class="nav-link">Inicio</a>
          </li>
          <li class="nav-item">
            <a @click="closeMenu" href="/precios" class="nav-link">Precios</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" @click="toggleIntegrationsMobile">
              Integraciones
              <span class="float-end" :class="{ 'rotated': isIntegrationsOpen }">▼</span>
            </a>
            <ul class="nav flex-column submenu" :class="{ 'show': isIntegrationsOpen }">
              <li>
                <a @click="closeMenu" href="/integraciones/whatsapp" class="nav-link">Chats de WhatsApp</a>
              </li>
              <li>
                <a @click="closeMenu" href="/integraciones/portales-inmobiliarios" class="nav-link">Portales Inmobiliarios</a>
              </li>
              <li>
                <a @click="closeMenu" href="/integraciones/google-calendar" class="nav-link">Google Calendar</a>
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <router-link @click="closeMenu" to="/faq" class="nav-link">Preguntas Frecuentes</router-link>
          </li>
        </ul>
        <a class="btn btn-primary w-100 mt-4" href="mailto:soporte@propmind.com">Contáctanos</a>
      </div>
    </div>
  </header>
</template>

<script>
export default {
name: 'AppNavbar',
data() {
  return {
    scrolled: false,
    isMenuOpen: false,
    showIntegrationsMenu: false,
    isIntegrationsOpen: false
  }
},
mounted() {
  window.addEventListener('scroll', this.handleScroll)
  window.addEventListener('keydown', this.handleEscKey)
},
beforeUnmount() {
  window.removeEventListener('scroll', this.handleScroll)
  window.removeEventListener('keydown', this.handleEscKey)
},
methods: {
  handleScroll() {
    this.scrolled = window.scrollY > 50
  },
  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen
    document.body.style.overflow = this.isMenuOpen ? 'hidden' : ''
  },
  closeMenu() {
    if (this.isMenuOpen) {
      this.isMenuOpen = false
      this.isIntegrationsOpen = false
      document.body.style.overflow = ''
    }
  },
  handleEscKey(e) {
    if (e.key === 'Escape') this.closeMenu()
  },
  toggleIntegrationsMobile() {
    this.isIntegrationsOpen = !this.isIntegrationsOpen
  }
}
}
</script>

<style scoped>
.navbar {
padding: 1rem 0;
transition: all 0.3s ease;
}

.navbar-scrolled {
background: rgba(255, 255, 255, 0.95);
backdrop-filter: blur(10px);
box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
padding: 0.5rem 0;
}

.logo {
transition: height 0.3s ease;
}

.nav-link {
position: relative;
padding: 0.5rem 1rem;
color: var(--dark-color);
font-weight: 500;
transition: color 0.3s ease;
margin: 0 0.765rem;
}

.nav-link:hover,
.nav-link.router-link-active {
color: var(--primary-color);
}

.nav-link::after {
content: '';
position: absolute;
bottom: 0;
left: 1rem;
right: 1rem;
height: 2px;
background: var(--primary-color);
transform: scaleX(0);
transition: transform 0.3s ease;
}

.nav-link:hover::after,
.nav-link.router-link-active::after {
transform: scaleX(1);
}

/* Dropdown Styles */
.dropdown-menu {
display: block;
opacity: 0;
visibility: hidden;
transform: translateY(10px);
transition: all 0.3s ease;
margin-top: 0;
border-radius: 8px;
border: none;
box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.dropdown-menu.show {
opacity: 1;
visibility: visible;
transform: translateY(0);
}

.dropdown-item {
padding: 0.75rem 1.5rem;
color: var(--dark-color);
transition: all 0.3s ease;
}

.dropdown-item:hover {
background-color: var(--light-color);
color: var(--primary-color);
}

/* Mobile Menu Styles */
.mobile-menu.active {
outline: none;
box-shadow: none;
}

.hamburger {
display: flex;
flex-direction: column;
justify-content: space-between;
height: 24px;
border: none;
background: none;
padding: 0;
}

.hamburger-line {
display: block;
width: 24px;
height: 3px;
border-radius: 3px;
background: var(--black-color);
transition: all 0.3s ease;
}

.hamburger.is-active .hamburger-line:nth-child(1) {
transform: translateY(11px) rotate(45deg);
}

.hamburger.is-active .hamburger-line:nth-child(2) {
opacity: 0;
}

.hamburger.is-active .hamburger-line:nth-child(3) {
transform: translateY(-11px) rotate(-45deg);
}

.mobile-menu {
position: fixed;
top: 93px;
left: 0;
right: 0;
bottom: 0;
visibility: hidden;
opacity: 0;
transition: all 0.3s ease;
}

.mobile-menu.is-open {
visibility: visible;
opacity: 1;
}

.mobile-menu-container {
background: white;
padding: 2rem;
transform: translateY(-100%);
transition: transform 0.3s ease;
}

.mobile-menu.is-open .mobile-menu-container {
transform: translateY(0);
}

.mobile-menu .nav-link {
font-size: 1.25rem;
padding: 1rem 0;
}

/* Mobile Submenu Styles */
.submenu {
max-height: 0;
overflow: hidden;
transition: max-height 0.3s ease;
padding-left: 1rem;
}

.submenu.show {
max-height: 500px;
}

.submenu .nav-link {
font-size: 1.1rem;
padding: 0.5rem 0;
}

.rotated {
display: inline-block;
transform: rotate(180deg);
transition: transform 0.3s ease;
}

@media (max-width: 991.98px) {
.navbar-toggler {
  padding: 0;
  border: none;
}
}
</style>