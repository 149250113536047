<template>
  <AppNavbar :startScrolled="true" />
  <section class="container-fluid d-flex justify-content-center bg-light my-5 pt-5">
      <div class="row container mt-4">
        <!-- Sidebar -->
        <div class="col-md-4 col-lg-3 px-0 sidebar border-0">
          <div class="bg-light p-3 card bg-white shadow-sm rounded-3">
            <!-- Property Type Filter -->
            <div class="mb-3">
              <label class="form-label fw-bold fs-5">Tipo de Propiedad</label>
              <div class="row d-flex">
                <div class="col-6" v-for="type in propertyTypes" :key="type">
                  <div class="form-check">
                    <input 
                      class="form-check-input" 
                      type="checkbox" 
                      v-model="filters.types[type.toLowerCase().replace(/\s+/g, '')]" 
                      :id="`${type.toLowerCase().replace(/\s+/g, '')}Check`"
                    >
                    <label class="form-check-label fs-6" :for="`${type.toLowerCase().replace(/\s+/g, '')}Check`">
                      {{ type }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
    
            <!-- Price Range Filter -->
            <div class="mb-3">
              <label for="priceRange" class="form-label fw-bold fs-5">Rango de Precio <small class="fs-7">(USD)</small></label>
              <div class="d-flex">
                <input 
                  type="number" 
                  class="form-control me-2" 
                  v-model.number="filters.priceMin" 
                  placeholder="Mín"
                >
                <input 
                  type="number" 
                  class="form-control" 
                  v-model.number="filters.priceMax" 
                  placeholder="Máx"
                >
              </div>
            </div>
    
            <!-- Area Range Filter -->
            <div class="mb-3">
              <label for="areaRange" class="form-label fw-bold fs-5">Rango de Superficie <small class="fs-7">(m²)</small></label>
              <div class="d-flex">
                <input 
                  type="number" 
                  class="form-control me-2" 
                  v-model.number="filters.areaMin" 
                  placeholder="Mín"
                >
                <input 
                  type="number" 
                  class="form-control" 
                  v-model.number="filters.areaMax" 
                  placeholder="Máx"
                >
              </div>
            </div>

            <!-- Bedrooms Filter -->
            <div class="mb-3">
              <label class="form-label fw-bold fs-5">Dormitorios</label>
              <select 
                class="form-select" 
                v-model="filters.bedrooms"
              >
                <option :value="null">Todos</option>
                <option :value="1">1 Dormitorio</option>
                <option :value="2">2 Dormitorios</option>
                <option :value="3">3 Dormitorios</option>
                <option :value="4">4+ Dormitorios</option>
              </select>
            </div>

            <!-- Bathrooms Filter -->
            <div class="mb-3">
              <label class="form-label fw-bold fs-5">Baños</label>
              <select 
                class="form-select" 
                v-model="filters.bathrooms"
              >
                <option :value="null">Todos</option>
                <option :value="1">1 Baño</option>
                <option :value="2">2 Baños</option>
                <option :value="3">3+ Baños</option>
              </select>
            </div>
    
            <!-- Status Filter -->
            <div class="mb-3">
              <label class="form-label fw-bold fs-5">Estado</label>
              <div class="form-check" v-for="status in propertyStatuses" :key="status">
                <input 
                  class="form-check-input" 
                  type="checkbox" 
                  v-model="filters.statuses[status.toLowerCase().replace(/\s+/g, '')]" 
                  :id="`${status.toLowerCase().replace(/\s+/g, '')}Check`"
                >
                <label class="form-check-label" :for="`${status.toLowerCase().replace(/\s+/g, '')}Check`">
                  {{ status }}
                </label>
              </div>
            </div>

            <!-- Location Filter -->
            <div class="mb-3">
              <label class="form-label fw-bold fs-5">Ubicación</label>
              <select 
                class="form-select" 
                v-model="filters.location"
              >
                <option value="">Todas las Ubicaciones</option>
                <option 
                  v-for="location in uniqueLocations" 
                  :key="location" 
                  :value="location"
                >
                  {{ location }}
                </option>
              </select>
            </div>
          </div>
        </div>
    
          <!-- Main Content -->
          <div class="col-md-8 col-lg-9 main-content px-0 mt-4 mt-lg-0">
            <div class="mx-lg-3 mx-0">
              <!-- Search Bar -->
              <div class="search-bar mb-4">
                <div class="input-group">
                  <span class="input-group-text px-4">
                    <i class="fas fa-search"></i>
                  </span>
                  <input 
                    type="text" 
                    class="form-control p-3" 
                    v-model="searchQuery" 
                    placeholder="Buscar por dirección, ciudad..."
                  >
                </div>
              </div>
      
              <!-- Properties Grid -->
              <div class="row g-lg-4">
                <div 
                  v-for="property in filteredProperties" 
                  :key="property.id" 
                  class="col-md-6 col-lg-4 my-0 px-3"
                >
                  <PropertyCard :property="property" />
                </div>
                
                <!-- No Results Message -->
                <div 
                  v-if="filteredProperties.length === 0" 
                  class="col-12 text-center mt-5"
                >
                  <i class="fas fa-search-minus fa-3x text-muted mb-3"></i>
                  <h4 class="text-muted">No se encontraron propiedades</h4>
                  <p>Intenta ajustar tus filtros o búsqueda</p>
                </div>
              </div>
            </div>
          </div>
        </div>
    </section>
    
    <AppFooter />
  </template>
  
  <script>
    import PropertyCard from './components/PropertyCard.vue'
    import AppNavbar from './components/Navbar.vue'
    import AppFooter from './components/Footer.vue'
    export default {
  name: 'DemoPropertiesSearch',
  components: {
    PropertyCard,
    AppNavbar,
    AppFooter
  },
  data() {
    return {
      searchQuery: '',
      propertyTypes: ['Departamento','Quinta', 'Casa', 'Terreno', 'Oficina', 'Local', 'Cochera', 'Edificio', 'Campo', 'PH', 'Depósito'],
      propertyStatuses: ['En Venta', 'Oportunidad', 'Nuevo'],
      filters: {
        types: {
          departamento: true,
          casa: true,
          loft: true
        },
        statuses: {
          enventa: true,
          oportunidad: true
        },
        priceMin: null,
        priceMax: null,
        areaMin: null,
        areaMax: null,
        bedrooms: null,
        bathrooms: null,
        location: ''
      },
      properties: [
        {
          id: 1,
          address: "Av. Libertador 1500, Palermo, CABA",
          description:
            "Espectacular departamento en el corazón de Palermo. Luminoso, con acabados de primera y vista panorámica a la ciudad.",
          price: 350000,
          type: "Departamento",
          status: "En Venta",
          bedrooms: 3,
          bathrooms: 2,
          area: 120,
          image: 'propiedad-1.webp'
        },
        {
          id: 2,
          address: "Barrio Cerrado Los Castores, Pilar, Buenos Aires",
          description:
            "Casa moderna en exclusivo barrio cerrado. Amplios espacios verdes, seguridad 24/7 y excelentes amenities para toda la familia.",
          price: 550000,
          type: "Casa",
          status: "Nuevo",
          bedrooms: 4,
          bathrooms: 3,
          area: 280,
          image: 'propiedad-2.webp'
        },
        {
          id: 3,
          address: "Belgrano 456, Mendoza Capital",
          description:
            "Propiedad histórica con encanto colonial, ubicada en el centro de Mendoza. Perfecta para inversión o vivienda familiar.",
          price: 280000,
          type: "Casa",
          status: "Oportunidad",
          bedrooms: 3,
          bathrooms: 2,
          area: 200,
          image: 'thumnail-1.jpg'
        },
        {
          id: 4,
          address: "Puerto Madero, CABA",
          description:
            "Loft de diseño con vista al río. Terminaciones de alta gama, amenities de primer nivel y ubicación incomparable.",
          price: 420000,
          type: "Loft",
          status: "En Venta",
          bedrooms: 2,
          bathrooms: 2,
          area: 90,
          image: 'propiedad-4.webp'
        },
        {
          id: 5,
          address: "Villa Carlos Paz, Córdoba",
          description:
            "Casa de fin de semana con vista al lago. Perfecta para descansar y disfrutar de la naturaleza. Ideal para inversión turística.",
          price: 250000,
          type: "Casa",
          status: "Rebajada",
          bedrooms: 3,
          bathrooms: 2,
          area: 160,
          image: 'propiedad-5.webp'
        },
        {
          id: 6,
          address: "San Telmo, CABA",
          description:
            "Departamento con el encanto típico del barrio porteño. Techos altos, detalles originales y ubicación histórica.",
          price: 210000,
          type: "Departamento",
          status: "Oportunidad",
          bedrooms: 2,
          bathrooms: 1,
          area: 85,
          image: 'propiedad-3.webp'
        },
      ],
    }
  },
  computed: {
    uniqueLocations() {
      return [...new Set(this.properties.map(p => p.address.split(',')[1].trim()))]
    },
    filteredProperties() {
      return this.properties.filter(property => {
        const typeMatch = this.filters.types[property.type.toLowerCase().replace(/\s+/g, '')]
        const statusMatch = this.filters.statuses[property.status.toLowerCase().replace(/\s+/g, '')]
        
        const searchMatch = property.address
          .toLowerCase()
          .includes(this.searchQuery.toLowerCase())
        
        const priceMatch = 
          (!this.filters.priceMin || property.price >= this.filters.priceMin) &&
          (!this.filters.priceMax || property.price <= this.filters.priceMax)
        
        const areaMatch = 
          (!this.filters.areaMin || property.area >= this.filters.areaMin) &&
          (!this.filters.areaMax || property.area <= this.filters.areaMax)
        
        const bedroomsMatch = 
          !this.filters.bedrooms || 
          (this.filters.bedrooms === 4 ? property.bedrooms >= 4 : property.bedrooms === this.filters.bedrooms)
        
        const bathroomsMatch = 
          !this.filters.bathrooms || 
          (this.filters.bathrooms === 3 ? property.bathrooms >= 3 : property.bathrooms === this.filters.bathrooms)
        
        const locationMatch = 
          !this.filters.location || 
          property.address.includes(this.filters.location)

        return typeMatch && statusMatch && searchMatch && 
               priceMatch && areaMatch && bedroomsMatch && 
               bathroomsMatch && locationMatch
      })
    }
  },
  methods: {
    clearFilters() {
      this.searchQuery = ''
      this.filters = {
        types: {
          departamento: true,
          casa: true,
          loft: true
        },
        statuses: {
          enventa: true,
          oportunidad: true
        },
        priceMin: null,
        priceMax: null,
        areaMin: null,
        areaMax: null,
        bedrooms: null,
        bathrooms: null,
        location: ''
      }
    }
  }
}
  </script>
  
  <style scoped>
  .sidebar {
    border-right: 1px solid #e0e0e0;
  }
.form-check-input:checked {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}

.form-check-input:focus {
    box-shadow: 0 0 0 0.25rem rgba(var(--primary-color-rgb), 0.25);
}
  </style>